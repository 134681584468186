* {
    transition: background-color 0.3s ease-in, color 0.3s ease-out;
}

::-webkit-scrollbar-thumb {
    transition: background-color 0.3s ease-in, color 0.3s ease-out;
}

::-webkit-scrollbar-track {
    transition: background-color 0.3s ease-in, color 0.3s ease-out;
}
